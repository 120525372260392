nav {
  position: sticky;
  //top: -1px;
  top: 0;
  z-index: 6;
  transition: height 0.5s;
  will-change: height;

  max-width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;


  .nav-wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    //height: 85px;
    height: 100px;
    position: sticky;
    top: -1px;
    border-bottom: 1px solid var(--borderColor);
    background-color: var(--bgColor);
    z-index: 6;


  }

  .logoWrapperSecond {
    display: flex;
    flex-direction: column;
    width: 100%;

    .logoWrapper {
      display: none;
      justify-content: center;
      align-items: center;
      margin-top: 2em;
      margin-bottom: 2em;
      padding: 0 1em;

      img {
        //display: none;
        width: auto;
        height: 3.5rem;
      }

      .hamburger {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        padding: 1em;

        .hamburger-label {
          width: 3rem;
          height: 2px;
          background-color: var(--thirdTextColor);

        }
      }

      .profileTMP {
        width: 50px;
        height: 11px;
        //background-color: red;
      }
    }

    .buttonWrapperSecond {
      display: flex;
    }
  }

  .nav-button {
    position: relative;
    flex: 1;
    height: 100%;
    background: none;
    border: none;
    font-size: 2rem;
    font-family: Raleway-SemiBold, sans-serif;
    cursor: pointer;
    text-align: center;


    .navButton-text {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      padding-bottom: 0.5em;
      height: 100%;
      font-size: 2rem;
      color: var(--thirdTextColor);
      transition: 0.3s;
      font-weight: normal;
    }

  }

  .activeSort {
    .navButton-text {
      color: var(--mainTextColor);
    }

  }
  .underline {
    position: absolute;
    bottom: -2px;
    height: 3px;
    background-color: var(--mintColor);
    transition: left 0.5s ease, width 0.5s ease;
  }

  .nav-postAndSourcePage {
    position: relative;
    flex: 1;
    height: 100%;
    background: none;
    border: none;
    font-size: 2rem;
    font-family: Raleway-SemiBold, sans-serif;
    text-align: center;

    display: flex;
    justify-content: center;
    align-items: center;

    .nav-postAndSourcePage-wrapper {
      display: flex;
      gap: 0.5em;
      margin-right: auto;
      cursor: pointer;
      padding: 1em;

      img {
        height: 2.5rem;
        width: auto;
        transition: 0.5s;
        max-height: 2.5rem;
      }
      p {
        font-family: Raleway-Regular, sans-serif;
        transition: 0.3s;
        color: var(--mainTextColor);
      }
    }

    .nav-postAndSourcePage-wrapper:hover {
      img {
        transform: translateX(-0.3em);
      }
      p {
        color: var(--mintColor);
      }
    }

    .nav-postAndSourcePage-singleText {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      justify-content: center;
      align-items: flex-end;
      padding-bottom: 0.5em;
      height: 100%;
      font-size: 2rem;
      font-weight: normal;
      color: var(--mainTextColor);
    }
  }

  .nav-postAndSourcePage::after {
    position: absolute;
    content: '';
    top: calc(100% - 1px);
    left: 0;
    right: 0;
    bottom: 0;
    width: 50%;
    height: 3px;
    background-color: var(--mintColor);
    transform: translateX(50%);
  }

  .mobileCategoryNav-Wrapper {
    display: none;
  }

  .customBr {
    height: 1px;
  }
}

nav::-webkit-scrollbar {
  display: none;
}


.nav-wrapper::-webkit-scrollbar {
  display: none;
}


@media (max-width: 800px) {
  nav {
    //transition: none;

    .logoWrapperSecond {
      .logoWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    .mobileCategoryNav-Wrapper {
      display: block;
      width: 100%;
      background-color: var(--bgColor);
      border-bottom: 1px solid var(--borderColor);
      z-index: 4;

      .mobile-category-button {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 1em;
        padding: 0.5em 2rem;
        will-change: transform;

        .category {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;

          .category-label {
            font-size: 2.2rem;
            font-family: Raleway-Regular, sans-serif;
            color: var(--mainTextColor);
          }
          .category-value {
            font-size: 2.2rem;
            font-family: Raleway-Bold, sans-serif;
            color: var(--mainTextColor);
          }
        }

        img {
          width: auto;
          height: 2rem;
          transition: 0.3s ease-out;
        }
      }
    }

    .mobileCategoryNav-Wrapper:hover {
      .mobile-category-button {
        img {
          transform: translateX(1rem);
        }
      }
    }

  }
  .nav-postAndSourcePage-wrapper {
    p {
      display: none;
    }
  }
}
