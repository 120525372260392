
.articleSources-container {
  //padding: 0 0 10em 0;

  .articleSources-list {

    .articleSources-item {

      a {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 2em 3em;
        text-decoration: none;
        color: var(--mainTextColor);
        gap: 4em;

        .articleSources-item-info {
          width: 70%;

          .articleSources-item-info-companyWrapper {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 0.5em;
            padding-bottom: 1em;

            .companyLogoSources {
              height: 1.5rem;
              width: auto;
            }

            .articleSources-item-info__sourceName {
              font-family: Raleway-Regular, sans-serif;
              font-size: 1.1rem;
              color: var(--mainTextColor);
            }
          }
          .articleSources-item-info__title {
            font-size: 1.8rem;
            font-family: Roboto-Medium, sans-serif;
            font-weight: normal;
            transition: color 0.5s;
          }
        }

        .articleSources-item-image {
          height: 100px;
          width: auto;
          aspect-ratio: 16/9;
          object-fit: cover;
          border-radius: 1rem;
        }
      }

      a:hover {
        .articleSources-item-info {
          .articleSources-item-info__title {
            color: var(--mintColor);
          }
        }

      }
    }
  }
}

@media (max-width: 800px) {
  .articleSources-container {
    .articleSources-list {
      .articleSources-item {
        a {
          gap: 2em;
          .articleSources-item-image {
            aspect-ratio: 1;
          }
        }
      }
    }
  }
  
}