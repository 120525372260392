
.sideBarContainer {
  width: 240px;
  padding: 0 1em;
  border-left: 1px solid var(--borderColor);
  max-width: 240px;
  position: sticky;
  top: 0;
  height: 100svh;
  //display: none;

  .sideBar-FollowBox {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding: 1em;
    border: 1px solid var(--borderColor);
    margin-top: 83px;
    border-radius: 1rem;

    .sideBar-FollowBox-bigText {
      font-family: Raleway-Bold, sans-serif;
      font-size: 2.2rem;
      color: var(--mainTextColor);
      padding-bottom: 0.5em;

    }

    .sideBar-FollowBox-text {
      font-family: Raleway-Regular, sans-serif;
      font-size: 1.6rem;
      color: var(--mainTextColor);
    }

    .sideBar-FollowBox-iconWrapper {
      display: flex;
      justify-content: space-around;
      align-items: center;
      gap: 2em;
      width: 100%;
      padding-top: 3em;
      padding-bottom: 1em;
      .sideBar-FollowBox-iconWrapper__icon {
        height: 3.8rem;
        width: auto;

      }
    }
  }
}

@media (max-width: 1300px) {
  .sideBarContainer {
    width: 1px;
    padding: 0;
    .sideBar-FollowBox {
      display: none;
    }
  }
}

@media (max-width: 800px) {
  .sideBarContainer {
    display: none;
  }
  
}