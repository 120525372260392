
article {

  .articleContent {

    .articleContent-grid {
      display: flex;
      flex-direction: row-reverse;
      margin-left: 3rem;
      justify-content: space-between;
      gap: 1.2em;

      .articleContent-grid-mainSource {
        width: calc(55% - 0.6em);

        a {
          text-decoration: none;
          color: var(--secondTextColor);

          .articleContent-imageWrapper {
            overflow: hidden;
            display: inline-block;
            border-radius: 1rem;
            width: 100%;
            height: auto;

            .articleContent-grid-mainSource-image {
              width: 100%;
              height: auto;
              transition: transform 0.5s ease-in-out;

              box-sizing: border-box;
              margin-bottom: -1px;
              padding: 0;
              display: block;
              //backface-visibility: hidden;
              //transform: translateZ(0);
              object-fit: cover;
              aspect-ratio: 16/9;
              will-change: transform;

            }
          }

          .articleContent-grid-mainSource-companyWrapper {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 0.5em 0 0.7em;
            gap: 0.5em;

            .companyLogo {
              height: 1.5rem;
              width: auto;
            }

            p {
              font-size: 1.3rem;
              font-family: Roboto-Regular, sans-serif;
            }
          }

          h4 {
            font-size: 1.8rem;
            font-weight: normal;
            font-family: Roboto-Medium, sans-serif;

            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: normal;
          }
        }

        a:hover {

          .articleContent-imageWrapper {

            .articleContent-grid-mainSource-image {
              //transform: scale(1.1) translateZ(0);
              transform: scale(1.1);
            }
          }
        }

      }
      .articleContent-grid-otherSources {
        display: flex;
        flex-direction: column;
        gap: 1.2em;
        width: calc(45% - 0.6em);

        .articleContent-grid-sources-element {
          background-color: var(--bgSecondColor);
          width: 100%;
          padding: 1em;
          border-radius: 1rem;
          transition: background-color 0.5s;
          a {
            text-decoration: none;


            .articleContent-imageWrapper {
              display: none;
            }
            .articleContent-grid-sources-element-imgOnlyMobile {
              display: none;
            }

            .articleContent-grid-sources-element-companyWrapper {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              gap: 0.5em;
              padding-bottom: 1em;

              .companyLogo {
                height: 1.5rem;
                width: auto;
              }


              .articleContent-grid-sources-element__sourceName {
                font-family: Raleway-Regular, sans-serif;
                font-size: 1.1rem;
                color: var(--mainTextColor);
              }
            }
            .articleContent-grid-sources-element__title {
              font-family: Roboto-Medium, sans-serif;
              font-size: 1.6rem;
              color: var(--mainTextColor);
              font-weight: normal;


              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: normal;
            }

          }
        }
        .articleContent-grid-sources-element:hover {
          background-color: var(--mintBackgroundColor);
        }
        .articleContent-grid-otherSources-showAll {
          display: flex;
          justify-content: space-between;
          width: 100%;
          background-color: var(--bgSecondColor);
          padding: 1em;
          border-radius: 1rem;
          z-index: 2;
          transition: background-color 0.5s;
          cursor: pointer;

          .arrowIconAllSources {
            margin-right: 1em;
            transition: 0.5s;
            will-change: translate;
          }

          .articleContent-grid-otherSources-showAll-textAndIcon {
            display: flex;
            width: 100%;
            gap: 1em;
            p {
              font-family: Roboto-Regular, sans-serif;
              font-size: 1.4rem;
              color: var(--mainTextColor);
            }
          }
        }

        .articleContent-grid-otherSources-showAll:hover {
          background-color: var(--mintBackgroundColor);

          .arrowIconAllSources {
            transform: translateX(1rem);
          }
          
        }
      }
    }

    .articleContent-summaryImage {
      margin-left: 3rem;
      padding-top: 4em;

      .summaryImage-bigText {
        font-family: Roboto-Medium, sans-serif;
        font-size: 2.2rem;
        color: var(--mainTextColor);
        position: relative;
      }
      .summaryImage-bigText:after {
        position: absolute;
        bottom: -2px;
        left: 0;
        right: 0;
        content: '';
        width: 100%;
        height: 2px;
        background-color: var(--mintColor);
      }

      .summaryImage-pointLine {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 1em;
        padding-top: 2em;
        position: relative;

        .summaryImage-pointLine-dot {
          height: 1.3rem;
          width: 1.3rem;
          background-color: transparent;
          margin-top: 5px;
          border-radius: 50%;
          border: 3px solid var(--mintColor);
        }

        .summaryImage-pointLine-text-wrapper {
          position: relative;
          width: calc(95% - 1em);
          overflow: hidden;

          .blurry-text {
            color: var(--mainTextColor);
            font-family: Roboto-Medium, sans-serif;
            font-size: 1.7rem;
            position: relative;
            z-index: 1;
          }

          .blurry-text::before {
            content: attr(data-text);
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            color: var(--mainTextColor);
            filter: blur(4px);
            clip-path: inset(50% 0 0 0);
            opacity: 0.5;
            z-index: -1;
          }

          .blurry-text::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(to bottom, transparent, var(--blurColor));
            z-index: 2;
            pointer-events: none;
          }
        }
      }
      .summaryImage-buttonWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 1em;
        padding-top: 1em;

        .summaryImage-buttonWrapper-text {
          color: var(--mainTextColor);
          font-size: 1.5rem;
          font-family: Raleway-Regular, sans-serif;
        }
        .summaryImage-buttonWrapper-img {
          height: 2rem;
          width: auto;
          rotate: 90deg;
          transition: 0.3s;
        }
      }
    }
  }
  .articleContent:hover {
    .articleContent-summaryImage {
      .summaryImage-buttonWrapper{
        .summaryImage-buttonWrapper-img {

          transform: translatex(0.5rem);
        }
      }
    }
  }
}

@media (max-width: 800px) {
  article {
    .articleContent {
      padding-left: 2rem;
      padding-right: 0;

      .articleContent-grid {
        display: flex;
        flex-direction: row;
        //gap: 1.2em;
        gap: 2em;
        flex-wrap: nowrap;
        overflow-x: scroll;
        scroll-snap-type: x mandatory;
        width: 100%;
        margin-left: 0;

        .articleContent-grid-mainSource {
          flex: 0 0 85%;
          scroll-snap-align: start;
        }


        .articleContent-grid-otherSources {
          flex-direction: row;
          //gap: 1.2em;
          gap: 2em;
          flex: 0 0 85%;

          .articleContent-grid-sources-element {
            flex: 0 0 100%;
            scroll-snap-align: start;
            padding: 0;
            background-color: transparent;
            width: 100%;

            a {

              .articleContent-imageWrapper {
                overflow: hidden;
                display: inline-block;
                border-radius: 1rem;
                width: 100%;
                height: auto;
              }
              .articleContent-grid-sources-element-imgOnlyMobile {
                display: block;
                width: 100%;
                height: auto;
                box-sizing: border-box;
                margin-bottom: -1px;
                padding: 0;
                aspect-ratio: 16/9;
                object-fit: cover;

              }

              .articleContent-grid-sources-element-companyWrapper {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 0.5em 0 0.7em;
                gap: 0.5em;
              }

              .articleContent-grid-sources-element__title {
                font-size: 1.8rem;
                font-weight: normal;
                font-family: Roboto-Medium, sans-serif;
              }
            }

          }

          .articleContent-grid-sources-element-showAllBig {
            flex: 0 0 100%;
            scroll-snap-align: start;
            width: 100%;
            .articleContent-sources-element-banner-wrapper {
              display: block;
              width: 100%;
              height: auto;
              box-sizing: border-box;
              margin-bottom: -1px;
              aspect-ratio: 16/9;
              object-fit: cover;
              border-radius: 1rem;
              padding-right: 2rem;

              .articleContent-sources-element-banner {
                background-color: var(--bgSecondColor);
                width: 100%;
                height: 100%;
                border-radius: 1rem;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 1em;
                p {
                  font-size: 1.8rem;
                  font-family: Roboto-Medium, sans-serif;
                  color: var(--mainTextColor);
                }

                img {
                  height: 1.8rem;
                  width: auto;
                }
              }
            }
          }



          .articleContent-grid-sources-element-showAll {
            border-radius: 1rem;
            transition: background-color 0.5s;


            flex: 0 0 100%;
            scroll-snap-align: start;
            padding: 0;
            background-color: transparent;
            width: 100%;
          }
          .articleContent-grid-sources-element:hover {
            background-color: transparent;
          }

          .articleContent-grid-otherSources-showAll {
            flex: 0 0 85%;
            scroll-snap-align: start;
            display: none;
          }
        }
      }

      .articleContent-summaryImage {
        padding-left: 0;
        padding-right: 2rem;
        margin-left: 0;
      }
    }

    .articleContent-grid::-webkit-scrollbar {
      display: none;
    }
  }
}

