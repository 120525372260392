article .articleContent .articleContent-grid {
  display: flex;
  flex-direction: row-reverse;
  margin-left: 3rem;
  justify-content: space-between;
  gap: 1.2em;
}
article .articleContent .articleContent-grid .articleContent-grid-mainSource {
  width: calc(55% - 0.6em);
}
article .articleContent .articleContent-grid .articleContent-grid-mainSource a {
  text-decoration: none;
  color: var(--secondTextColor);
}
article .articleContent .articleContent-grid .articleContent-grid-mainSource a .articleContent-imageWrapper {
  overflow: hidden;
  display: inline-block;
  border-radius: 1rem;
  width: 100%;
  height: auto;
}
article .articleContent .articleContent-grid .articleContent-grid-mainSource a .articleContent-imageWrapper .articleContent-grid-mainSource-image {
  width: 100%;
  height: auto;
  transition: transform 0.5s ease-in-out;
  box-sizing: border-box;
  margin-bottom: -1px;
  padding: 0;
  display: block;
  object-fit: cover;
  aspect-ratio: 16/9;
  will-change: transform;
}
article .articleContent .articleContent-grid .articleContent-grid-mainSource a .articleContent-grid-mainSource-companyWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5em 0 0.7em;
  gap: 0.5em;
}
article .articleContent .articleContent-grid .articleContent-grid-mainSource a .articleContent-grid-mainSource-companyWrapper .companyLogo {
  height: 1.5rem;
  width: auto;
}
article .articleContent .articleContent-grid .articleContent-grid-mainSource a .articleContent-grid-mainSource-companyWrapper p {
  font-size: 1.3rem;
  font-family: Roboto-Regular, sans-serif;
}
article .articleContent .articleContent-grid .articleContent-grid-mainSource a h4 {
  font-size: 1.8rem;
  font-weight: normal;
  font-family: Roboto-Medium, sans-serif;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
article .articleContent .articleContent-grid .articleContent-grid-mainSource a:hover .articleContent-imageWrapper .articleContent-grid-mainSource-image {
  transform: scale(1.1);
}
article .articleContent .articleContent-grid .articleContent-grid-otherSources {
  display: flex;
  flex-direction: column;
  gap: 1.2em;
  width: calc(45% - 0.6em);
}
article .articleContent .articleContent-grid .articleContent-grid-otherSources .articleContent-grid-sources-element {
  background-color: var(--bgSecondColor);
  width: 100%;
  padding: 1em;
  border-radius: 1rem;
  transition: background-color 0.5s;
}
article .articleContent .articleContent-grid .articleContent-grid-otherSources .articleContent-grid-sources-element a {
  text-decoration: none;
}
article .articleContent .articleContent-grid .articleContent-grid-otherSources .articleContent-grid-sources-element a .articleContent-imageWrapper {
  display: none;
}
article .articleContent .articleContent-grid .articleContent-grid-otherSources .articleContent-grid-sources-element a .articleContent-grid-sources-element-imgOnlyMobile {
  display: none;
}
article .articleContent .articleContent-grid .articleContent-grid-otherSources .articleContent-grid-sources-element a .articleContent-grid-sources-element-companyWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5em;
  padding-bottom: 1em;
}
article .articleContent .articleContent-grid .articleContent-grid-otherSources .articleContent-grid-sources-element a .articleContent-grid-sources-element-companyWrapper .companyLogo {
  height: 1.5rem;
  width: auto;
}
article .articleContent .articleContent-grid .articleContent-grid-otherSources .articleContent-grid-sources-element a .articleContent-grid-sources-element-companyWrapper .articleContent-grid-sources-element__sourceName {
  font-family: Raleway-Regular, sans-serif;
  font-size: 1.1rem;
  color: var(--mainTextColor);
}
article .articleContent .articleContent-grid .articleContent-grid-otherSources .articleContent-grid-sources-element a .articleContent-grid-sources-element__title {
  font-family: Roboto-Medium, sans-serif;
  font-size: 1.6rem;
  color: var(--mainTextColor);
  font-weight: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
article .articleContent .articleContent-grid .articleContent-grid-otherSources .articleContent-grid-sources-element:hover {
  background-color: var(--mintBackgroundColor);
}
article .articleContent .articleContent-grid .articleContent-grid-otherSources .articleContent-grid-otherSources-showAll {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: var(--bgSecondColor);
  padding: 1em;
  border-radius: 1rem;
  z-index: 2;
  transition: background-color 0.5s;
  cursor: pointer;
}
article .articleContent .articleContent-grid .articleContent-grid-otherSources .articleContent-grid-otherSources-showAll .arrowIconAllSources {
  margin-right: 1em;
  transition: 0.5s;
  will-change: translate;
}
article .articleContent .articleContent-grid .articleContent-grid-otherSources .articleContent-grid-otherSources-showAll .articleContent-grid-otherSources-showAll-textAndIcon {
  display: flex;
  width: 100%;
  gap: 1em;
}
article .articleContent .articleContent-grid .articleContent-grid-otherSources .articleContent-grid-otherSources-showAll .articleContent-grid-otherSources-showAll-textAndIcon p {
  font-family: Roboto-Regular, sans-serif;
  font-size: 1.4rem;
  color: var(--mainTextColor);
}
article .articleContent .articleContent-grid .articleContent-grid-otherSources .articleContent-grid-otherSources-showAll:hover {
  background-color: var(--mintBackgroundColor);
}
article .articleContent .articleContent-grid .articleContent-grid-otherSources .articleContent-grid-otherSources-showAll:hover .arrowIconAllSources {
  transform: translateX(1rem);
}
article .articleContent .articleContent-summaryImage {
  margin-left: 3rem;
  padding-top: 4em;
}
article .articleContent .articleContent-summaryImage .summaryImage-bigText {
  font-family: Roboto-Medium, sans-serif;
  font-size: 2.2rem;
  color: var(--mainTextColor);
  position: relative;
}
article .articleContent .articleContent-summaryImage .summaryImage-bigText:after {
  position: absolute;
  bottom: -2px;
  left: 0;
  right: 0;
  content: "";
  width: 100%;
  height: 2px;
  background-color: var(--mintColor);
}
article .articleContent .articleContent-summaryImage .summaryImage-pointLine {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1em;
  padding-top: 2em;
  position: relative;
}
article .articleContent .articleContent-summaryImage .summaryImage-pointLine .summaryImage-pointLine-dot {
  height: 1.3rem;
  width: 1.3rem;
  background-color: transparent;
  margin-top: 5px;
  border-radius: 50%;
  border: 3px solid var(--mintColor);
}
article .articleContent .articleContent-summaryImage .summaryImage-pointLine .summaryImage-pointLine-text-wrapper {
  position: relative;
  width: calc(95% - 1em);
  overflow: hidden;
}
article .articleContent .articleContent-summaryImage .summaryImage-pointLine .summaryImage-pointLine-text-wrapper .blurry-text {
  color: var(--mainTextColor);
  font-family: Roboto-Medium, sans-serif;
  font-size: 1.7rem;
  position: relative;
  z-index: 1;
}
article .articleContent .articleContent-summaryImage .summaryImage-pointLine .summaryImage-pointLine-text-wrapper .blurry-text::before {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: var(--mainTextColor);
  filter: blur(4px);
  clip-path: inset(50% 0 0 0);
  opacity: 0.5;
  z-index: -1;
}
article .articleContent .articleContent-summaryImage .summaryImage-pointLine .summaryImage-pointLine-text-wrapper .blurry-text::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, transparent, var(--blurColor));
  z-index: 2;
  pointer-events: none;
}
article .articleContent .articleContent-summaryImage .summaryImage-buttonWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1em;
  padding-top: 1em;
}
article .articleContent .articleContent-summaryImage .summaryImage-buttonWrapper .summaryImage-buttonWrapper-text {
  color: var(--mainTextColor);
  font-size: 1.5rem;
  font-family: Raleway-Regular, sans-serif;
}
article .articleContent .articleContent-summaryImage .summaryImage-buttonWrapper .summaryImage-buttonWrapper-img {
  height: 2rem;
  width: auto;
  rotate: 90deg;
  transition: 0.3s;
}
article .articleContent:hover .articleContent-summaryImage .summaryImage-buttonWrapper .summaryImage-buttonWrapper-img {
  transform: translatex(0.5rem);
}

@media (max-width: 800px) {
  article .articleContent {
    padding-left: 2rem;
    padding-right: 0;
  }
  article .articleContent .articleContent-grid {
    display: flex;
    flex-direction: row;
    gap: 2em;
    flex-wrap: nowrap;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    width: 100%;
    margin-left: 0;
  }
  article .articleContent .articleContent-grid .articleContent-grid-mainSource {
    flex: 0 0 85%;
    scroll-snap-align: start;
  }
  article .articleContent .articleContent-grid .articleContent-grid-otherSources {
    flex-direction: row;
    gap: 2em;
    flex: 0 0 85%;
  }
  article .articleContent .articleContent-grid .articleContent-grid-otherSources .articleContent-grid-sources-element {
    flex: 0 0 100%;
    scroll-snap-align: start;
    padding: 0;
    background-color: transparent;
    width: 100%;
  }
  article .articleContent .articleContent-grid .articleContent-grid-otherSources .articleContent-grid-sources-element a .articleContent-imageWrapper {
    overflow: hidden;
    display: inline-block;
    border-radius: 1rem;
    width: 100%;
    height: auto;
  }
  article .articleContent .articleContent-grid .articleContent-grid-otherSources .articleContent-grid-sources-element a .articleContent-grid-sources-element-imgOnlyMobile {
    display: block;
    width: 100%;
    height: auto;
    box-sizing: border-box;
    margin-bottom: -1px;
    padding: 0;
    aspect-ratio: 16/9;
    object-fit: cover;
  }
  article .articleContent .articleContent-grid .articleContent-grid-otherSources .articleContent-grid-sources-element a .articleContent-grid-sources-element-companyWrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0.5em 0 0.7em;
    gap: 0.5em;
  }
  article .articleContent .articleContent-grid .articleContent-grid-otherSources .articleContent-grid-sources-element a .articleContent-grid-sources-element__title {
    font-size: 1.8rem;
    font-weight: normal;
    font-family: Roboto-Medium, sans-serif;
  }
  article .articleContent .articleContent-grid .articleContent-grid-otherSources .articleContent-grid-sources-element-showAllBig {
    flex: 0 0 100%;
    scroll-snap-align: start;
    width: 100%;
  }
  article .articleContent .articleContent-grid .articleContent-grid-otherSources .articleContent-grid-sources-element-showAllBig .articleContent-sources-element-banner-wrapper {
    display: block;
    width: 100%;
    height: auto;
    box-sizing: border-box;
    margin-bottom: -1px;
    aspect-ratio: 16/9;
    object-fit: cover;
    border-radius: 1rem;
    padding-right: 2rem;
  }
  article .articleContent .articleContent-grid .articleContent-grid-otherSources .articleContent-grid-sources-element-showAllBig .articleContent-sources-element-banner-wrapper .articleContent-sources-element-banner {
    background-color: var(--bgSecondColor);
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
  }
  article .articleContent .articleContent-grid .articleContent-grid-otherSources .articleContent-grid-sources-element-showAllBig .articleContent-sources-element-banner-wrapper .articleContent-sources-element-banner p {
    font-size: 1.8rem;
    font-family: Roboto-Medium, sans-serif;
    color: var(--mainTextColor);
  }
  article .articleContent .articleContent-grid .articleContent-grid-otherSources .articleContent-grid-sources-element-showAllBig .articleContent-sources-element-banner-wrapper .articleContent-sources-element-banner img {
    height: 1.8rem;
    width: auto;
  }
  article .articleContent .articleContent-grid .articleContent-grid-otherSources .articleContent-grid-sources-element-showAll {
    border-radius: 1rem;
    transition: background-color 0.5s;
    flex: 0 0 100%;
    scroll-snap-align: start;
    padding: 0;
    background-color: transparent;
    width: 100%;
  }
  article .articleContent .articleContent-grid .articleContent-grid-otherSources .articleContent-grid-sources-element:hover {
    background-color: transparent;
  }
  article .articleContent .articleContent-grid .articleContent-grid-otherSources .articleContent-grid-otherSources-showAll {
    flex: 0 0 85%;
    scroll-snap-align: start;
    display: none;
  }
  article .articleContent .articleContent-summaryImage {
    padding-left: 0;
    padding-right: 2rem;
    margin-left: 0;
  }
  article .articleContent-grid::-webkit-scrollbar {
    display: none;
  }
}


