.notFound-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  padding: 6em 2em;
}
.notFound-wrapper .notFound-img-wrapper {
  display: flex;
  justify-content: center;
  padding-bottom: 3em;
}
.notFound-wrapper .notFound-img-wrapper .notFound-img {
  width: 350px;
  height: auto;
  margin-left: 65px;
}
.notFound-wrapper .notFound-text {
  text-align: center;
  font-size: 2.5rem;
  font-family: Raleway-Regular, sans-serif;
  color: var(--mainTextColor);
}
.notFound-wrapper .notFound-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5em;
}
.notFound-wrapper .notFound-button-wrapper button {
  background-color: transparent;
  border: 2px solid var(--mintColor);
  padding: 0.5em 1em;
  font-family: Raleway-Regular, sans-serif;
  border-radius: 1rem;
  font-size: 2rem;
  color: var(--mainTextColor);
  cursor: pointer;
  transition: 0.5s ease;
}
.notFound-wrapper .notFound-button-wrapper button:hover {
  background-color: var(--mintColor);
}

@media (max-width: 800px) {
  .notFound-wrapper .notFound-img-wrapper .notFound-img {
    width: 250px;
    margin-left: 55px;
  }
  .notFound-wrapper .fetchError-text {
    font-size: 2rem;
  }
}


